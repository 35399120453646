<template>
  <div>{{ value }}</div>
</template>

<script>
export default {
  name: "ProReportFileSizeTdField",
  props: {
    value: null,
  },
};
</script>
